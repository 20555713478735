
// Entry point for the build script in your package.json
//import { Turbo } from "@hotwired/turbo-rails"

import './add_jquery'

//= require jquery
//= require bootstrap-dropdown
//= require bootstrap
//= require_tree
//= require jquery_ujs
//= require bootstrap-datepicker
//= require bootstrap-sprockets
//= require bootstrap-treeview
//= require_self
//= require jquery-ui


import "./controllers"
import * as bootstrap from "bootstrap"
import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo


document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
})


$(document).ready(function() {

  $("form").submit(function(event) {
    $(".divLoading").show();
  });
});


$(document).ready( function() {
  $('.toggle').click(function(e) {
    e.preventDefault();        
    var $this = $(this);
    var $nextULAfterParentLi = $this.closest('li').find('ul.inner');
    if ($nextULAfterParentLi.hasClass('show')) {
        $nextULAfterParentLi.removeClass('show');
        $nextULAfterParentLi.slideUp(350);
    } else {
        $this.closest('ul').find('li .inner').slideUp(350);
        $this.closest('ul').find('li .inner').removeClass('show');
        $this.closest('li').find('.inner:first').slideToggle(350);
        $this.closest('li').find('.inner:first').toggleClass('show');
    }
  });

  var sticky = $('#sticky'),
      stickyClone,
      stickyTop = sticky.offset().top,
      scrollTop,
      scrolled = false,
      $window = $(window);

  $window.on('scroll', function (e) {
    scrollTop = $window.scrollTop();
    if (scrollTop >= stickyTop && !stickyClone) {
      /* Attach a clone to replace the "missing" body height */
      stickyClone = sticky.clone().prop('id', sticky.prop('id') + '-clone')
      stickyClone = stickyClone.insertBefore(sticky);
      sticky.addClass('fixed');
    } else if (scrollTop < stickyTop && stickyClone) {
      /* Since sticky is in the viewport again, we can remove the clone and the class */
      stickyClone.remove();
      stickyClone = null;
      sticky.removeClass('fixed');
    }
  });
});

var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
var collapseList = collapseElementList.map(function (collapseEl) {
  return new bootstrap.Collapse(collapseEl)
})
